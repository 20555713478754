module.exports = {
  'title_login': 'サインイン',
  'subtitle': 'アヤナ リワードに登録したEメールアドレスを入力し次に進む',
  'placeholder_email': 'Eメールアドレス',
  'placeholder_password': 'パスワードの入力',
  'remember_label': 'ログイン状態を保存する',
  'forgot_password_label': 'パスワードを忘れた',
  'btn_label': 'サインイン',
  'title_another_login': 'またはソーシャルメディアを通じてログインする',
  'login_error_email_text': 'ご入力頂いたEメールアドレスは見つかりませんでした',
  'login_error_email_text_empty': 'Email can not be null',
  'login_error_password_text': 'Wrong password',
  'login_error_password_text_empty': 'Password can not be null',
  'sugest_register_link': '代わりに新しいアカウント',
  'register_link': 'の登録をご希望され',
  'sugest_register_link_2': 'ますか？',
  'btn_next_label': '次へ',
  'title_register': 'まだアヤナリワード会員ではありませんか？ ',
  'join_label': '登録する',
  'password_info': 'パスワードをご入力ください',
  'login_error_no_connection': '接続することができません。 インターネット接続を確認して、もう一度お試しください。'
}



