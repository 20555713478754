import React, { Component } from "react";
import { Link, navigate } from "gatsby";

import Layout from "../layouts/ja";
import SEO from "../components/seo";
import Login from "../services/Login";
import LoginLang from "../data/static-pages/pages/login/ja";
import { isLoggedIn, getCurrentUser } from "../services/Auth";

export default class LoginPage extends Component {
  constructor(props, context) {
    super(props, context);
    this.urlSignUp = "/ja/signup";
    this.urlForgetPassword = "/ja/forget-password";
    this.urlRedirect = "/ja/member";
    this.activationUrl = "/ja/activation";
    this.verifyAccountUrl = "/ja/verify-account";
    this.loginUrl = "/ja/login";
    this.redemptionUrl = "/ja/member/redemption";
    this.langKey = "ja";
  }

  componentDidMount() {
    this.checkLoginStatus();
  }

  checkLoginStatus() {
    if (isLoggedIn() == true) navigate("/ja/member");
  }

  render() {
    return (
      <Layout location={this.props.location} footer_margin={true}>
        <SEO title="Login " keyword="Login" decription="Login" />

        <Login
          props={this.props}
          urlRedirect={this.urlRedirect}
          langData={LoginLang}
          urlSignUp={this.urlSignUp}
          urlForgetPassword={this.urlForgetPassword}
          activationUrl={this.activationUrl}
          verifyAccountUrl={this.verifyAccountUrl}
          loginUrl={this.loginUrl}
          redemptionUrl={this.redemptionUrl}
          langKey={this.langKey}
        />
      </Layout>
    );
  }
}
